<script>
  /*eslint-disable */
  import {mapActions, mapGetters} from 'vuex';
  import Axios from "axios";
  import BlockPagination from '../../components/utility/block-pagination';

  export default {
    name: 'Repairers',
    filters: {
      isActive(val) {
        if (val === true) {
          return 'Yes';
        }
        return 'No';
      },
      formatClaimType(val) {
        if (val == 'not_set') {
          return '';
        }
        return val;
      }
    },
    data: function () {
      return {
        repairers: [],
        repairersFiltered: [],
        filter: '',
        sortBy: 'number',
        sortDesc: true,
        perPage: 50,
        currentPage: 1,
        allFields: [
          {label: "Repairer Name", key: "businessName", sortable: true, tdClass: 'clickable number', thClass: ''},
          {label: "Repairer Type", key: "type", sortable: true, tdClass: 'clickable ', thClass: ''},
          {label: "Contact Nbr", key: "phone", sortable: true, tdClass: 'clickable number', thClass: ''},
          {label: "Suburb", key: "suburb", sortable: true, tdClass: 'clickable ', thClass: ''},
          {label: "State", key: "state", sortable: true, tdClass: 'clickable ', thClass: ''},
          {label: "Active", key: "active", sortable: true, tdClass: 'clickable', thClass: ''},
          {label: "Date Created", key: "createdOn", sortable: false, tdClass: 'clickable ', thClass: ''},
          {label: "Action", key: "actions", sortable: false, thClass: ''},
        ],

      };
    },
    methods: {
      ...mapActions({
        setFilter: 'repairers/setFilter',
        setTableSortBy: 'repairers/setTableSortBy',
        setTableSortDesc: 'repairers/setTableSortDesc',
      }),
      onFiltered(fi) {
        this.repairersFiltered = fi;
      },
      tabChanged(selectedTab) {
        this.currentTab = selectedTab.tab.name;
      },
      clickAddRepairer(){
        NProgress.start();
        Axios.post('/ir/repairers', {})
            .then(response => {
              if (response && response.data && response.data._status) {
                this.$router.push(`/repairer/${response.data.id}`);
                this.$toast.success('Repairer Created');
              }
            })
            .catch(e => console.error(e))
            .finally(() => {
              NProgress.done();
            });

      },
      clickRepairerRow(data){
        this.$router.push(`/repairer/${data.id}`);
      },
      loadRepairers() {
        NProgress.start();
        Axios.get('/ir/repairers')
                .then(response => {
                  if (response && response.data && response.data._status) {
                    this.repairers = response.data.data;
                    this.repairersFiltered = response.data.data;
                  }
                })
                .catch(e => console.error(e))
                .finally(() => {
                  NProgress.done();
                });
      },
      changeAllRepairersPage(){

      },
      editRepairer(repairer){
        this.$router.push(`/repairer/${repairer.id}#repairer-view-business`)
      },
      deleteRepairer(repairer){
        if(!confirm(`Are you sure to delete ${repairer.businessName}?`)) return;
        Axios.post(`/ir/repairer/${repairer.id}/remove`, {repairerId: repairer.id})
            .then(response => {
              if (response && response.data && response.data._status) {
                this.$toast.success(`Repairer company ${repairer.businessName} has been removed`);
                this.loadRepairers();
              }
            })
            .catch(e => console.error(e));
      },
    },
    mounted() {
      if (!this.filter) this.filter = this.gettersFilter;
      if (!this.sortBy) this.sortBy = this.gettersTableSortBy;
      if (!this.sortDesc) this.sortDesc = this.gettersTableSortDesc;
      if (this.gettersFilter) this.filter = this.gettersFilter;
      if (this.gettersTableSortBy != null) this.sortBy = this.gettersTableSortBy;
      if (this.gettersTableSortDesc != null) this.sortDesc = this.gettersTableSortDesc;
      this.loadRepairers();
    },
    computed: {
      ...mapGetters({
        gettersFilter: 'repairers/getFilter',
        gettersTableSortBy: 'repairers/getTableSortBy',
        gettersTableSortDesc: 'repairers/getTableSortDesc',
      }),
      computedRepairersFiltered() {
        return this.repairersFiltered;
      },
    },
    components: {
      BlockPagination,
    },
    watch: {
      filter(val) {
        this.setFilter(val);
      },
      sortBy(val) {
        this.setTableSortBy(val);
      },
      sortDesc(val) {
        this.setTableSortDesc(val);
      },
    },
}
</script>

<template>
  <div class="claimlinq-page">
    <div class="page-header">
      <h4>Repairers</h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item">
          <router-link :to="'/dashboard'">Home</router-link>
        </li>
        <li class="breadcrumb-item active">Repairers</li>
      </ol>
      <div class="page-header-nav-btn">
        <a @click="clickAddRepairer" class="btn btn-primary btn-submit-quote">
          <i class='bx bx-plus'></i><span>Add Repairer</span>
        </a>
      </div>
    </div>
    <div class="claimlinq-body">
      <tabs ref="claimlinq-table" @changed="tabChanged" cache-lifetime="0" :options="{useUrlFragment:true}">
        <tab name="All" id="claims-all" :class-name="'tab-content-table'">
          <div class="use-pagination-block-bottom">
            <b-table
                    responsive
                    hover
                    :items="repairers"
                    :fields="allFields"
                    @filtered="onFiltered"
                    :filter="filter"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    @row-clicked="clickRepairerRow"
            >
              <template v-slot:head()="data">
                <div v-if="!data.field.sortable">{{data.label}}</div>
                <div v-if="data.field.sortable" class="table-sorting">{{data.label}}<i></i></div>
              </template>

              <template v-slot:cell(active)="data">
                <span v-if="data.item.active">
                  <i class='bx bx-check-circle font-20 text-success'></i>
                </span>
                <span v-else>
                  <i class='bx bx-x-circle font-20 text-danger'></i>
                </span>
              </template>

              <template v-slot:cell(createdOn)="data">
                {{ data.item.createdOn | formatDatetime }}
              </template>

              <template v-slot:cell(type)="data">
                <template v-if="data.item.type == 'internal'">Internal</template>
                <template v-if="data.item.type == 'subcontractor'">Subcontractor</template>
                <template v-if="data.item.type == 'external'">External</template>
              </template>

              <template v-slot:cell(actions)="data">
                <a href="#" class="mr-0-5" @click.prevent="editRepairer(data.item)">
                  <i class='bx bx-edit font-20 text-dropbox'></i>
                </a>
                <a href="#" @click.prevent="deleteRepairer(data.item)">
                  <i class='bx bx-trash font-20 text-danger'></i>
                </a>

              </template>

            </b-table>
          </div>
          <block-pagination
              :role="'Repairers'"
              :arrayOfSomeone="repairers"
              :arrayOfSomeoneFiltered="computedRepairersFiltered"
              :currentPage="currentPage"
              @changedCurrentPage="value => currentPage = value"
              :perPage="perPage"
              @changedPerPage="value => perPage = value"
          >
          </block-pagination>

        </tab>
        <template slot="nav-item-right">
          <div class="tabs-nav-right-search">
            <b-form-input v-model="filter" type="text" class="search-input" placeholder="Search or Filter results" debounce="100"></b-form-input>
          </div>
          <div class="tabs-nav-right-btn ml-4">
            <a @click="clickAddRepairer" class="btn btn-primary btn-submit-quote">
              <i class='bx bx-plus'></i><span>Add Repairer</span>
            </a>
          </div>
        </template>
      </tabs>
    </div>
  </div>
</template>
